<template>
  <v-dialog v-model="show" persistent max-width="600">
    <v-card>
      <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
      <v-card-title class="headline text-center"><h3>{{title}}</h3></v-card-title>
      <v-divider class="mx-4 mb-5"></v-divider>
      <v-card-text>
        <div v-if="personnelSearchLoading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-row v-else>
        <v-col cols="12">
          <!-- <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          /> -->
          <v-autocomplete
            v-model="model"
            :items="entries"
            :loading="isLoading"
            :search-input.sync="search"
            color="#4e4e50"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="mame"
            placeholder="Start typing to Search"
            post-icon="mdi-database-search"
            @change="selectPersonnel"
            return-object
            outlined
            dense
          ></v-autocomplete>
          <div v-if="mType =='forward'">
           <v-text-field
            v-model="comment"
            outlined
            label="Comment"
            @blur="commentPersonnel"
            return-object
            dense
          />
          </div>
        </v-col>
        <v-col cols="12">
          <p  class="text-left">Selected: <b v-if="model">{{model.name}}</b> <span v-else>None</span></p>
          <!-- <v-data-table
            dense
            v-model="personnel"
            item-key="id"
            @click:row="selectPersonnel"
            :headers="searchHeaders"
            :items="personnelSearch"
            class="elevation-1 row-pointer"
            :hide-default-footer="true"
          ></v-data-table> -->
        </v-col>
      </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <slot></slot>
        <Button
          :label="'Close'"
          :btnType="'Submit'"
          @onClick="okayBtn"
          :isLoading="loading"
          class="mr-4"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { employeeService } from '@/services'
import Button from './form/Button.vue'
export default {
  components: {
    Button
  },
  props: {
    dialog: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'personnel'
    },
    mType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Personnel Search'
    }
  },
  computed: {
    show: {
      get () {
        return this.dialog
      },
      set (dialog) {
        if (!dialog) {
          this.$emit('close')
        }
      }
    },
    loading: {
      get () {
        return this.isLoading
      },
      set (isLoading) {
        if (!isLoading) {
          this.$emit('close')
        }
      }
    }
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      search: '',
      selected: null,
      isLoading: false,
      personnel: '',
      personnelName: '',
      comment: '',
      // forward:false,
      model: null,
      entries: [],
      personnelSearchLoading: false,
      personnelSearch: []
    }
  },
  watch: {
    search (val) {
      if (this.isLoading) return

      if (!val || val === null || val.includes('%')) return val

      if (val.length < 3) {
        return
      }

      this.isLoading = true

      if (this.type === 'personnel') {
        employeeService.searchPersonnel(val).then(result => {
          this.entries = result.data
        }).catch(() => {
          this.showAlertMessage('Unable to load, Try again', 'error')
        }).finally(() => {
          this.personnelSearchLoading = false
          this.selected = null
          this.isLoading = false
        })
        return
      }
      if (this.type === 'employee') {
        employeeService.searchEmployees(val).then(result => {
          this.entries = result.data
        }).catch(() => {
          this.showAlertMessage('Unable to load, Try again', 'error')
        }).finally(() => {
          this.personnelSearchLoading = false
          this.selected = null
          this.isLoading = false
        })
      }
      // Lazily load input items
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    // selectPersonnel (item) {
    //   const dataItem =[]
    //   dataItem.push({
    //   selected: item,
    //   comment: this.comment
    //   })
    //   // this.selected = item
    //   console.log('dataItem:', dataItem)
    //   this.$emit('selectPer', dataItem)
    // },
    selectPersonnel (item) {
      this.selected = item
      console.log('item', item)
      this.$emit('selectPer', item)
    },
    commentPersonnel () {
      const comment = this.comment
      console.log('Comment', comment)
      this.$emit('commentPer', comment)
    },
    okayBtn () {
      this.show = false
      this.personnelSearch = []
      this.selected = null
      this.search = ``
      this.model = null
    }
  }
}
</script>
